import React, { useEffect, useRef, useState } from "react";
import "../Assets/Home.css";
import imageOne from "../Assets/Assets/slider_image_1-scaled.jpg";
import imageTwo from "../Assets/Assets/slider_image_2-scaled.jpg";
import ProductsImage5 from "../Assets/Assets/product-image-5.png";
import ProductsImage7 from "../Assets/Assets/product-image-7.png";
import ProductImage8 from "../Assets/Assets/product-image-8.png";
import ProductImage9 from "../Assets/Assets/product-image-9.png";
import HomeImage from "../Assets/SVG/Home-More-Services.svg";
import HomeImage1 from "../Assets/Assets/Home-Map-Image.png";
import HomeImage3 from "../Assets/Assets/Map-Country-1.png";
import HomeImage4 from "../Assets/Assets/Map-Country-2.png";
import HomeImage5 from "../Assets/Assets/Map-Country-3.png";
import HomeImage6 from "../Assets/Assets/Map-Country-4.png";
import HomeImage7 from "../Assets/Assets/Map-Country-5.png";
import HomeImage8 from "../Assets/Assets/Map-Country-6.png";
import HomeImage9 from "../Assets/Assets/Map-Country-7.png";
import HomeImage10 from "../Assets/Assets/Map-Country-8.png";
import HomeImage11 from "../Assets/Assets/Map-Country-9.png";
import HomeImage12 from "../Assets/Assets/Map-Country-10.png";
import WnPay from "../Assets/Assets/Wnpay.png";
import WnSoc from "../Assets/Assets/WnSoc.png";
import WnDigital from "../Assets/Assets/WnDigital.png";
import WnvoecProduct from "../Assets/Assets/WnHomelogo.png";
import asset4 from "../Assets/Assets/Home-Tab-Image.png";
import bannerFrontHomePage from "../Assets/Assets/Banner_page-0001.jpg";
import bannerSmallImage from "../Assets/Assets/bannerSmallImage.jpg";
import TabsComponent from "../Components/TabComponent";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import wnHomeimage5 from "../Assets/SVG/wnhome-arrow-prev.svg";
import wnHomeimage6 from "../Assets/SVG/wnhome-arrow-next.svg";
import WeContainers from "../Assets/Assets/WeContainers.png";
function Home({ eng, chinese, spanish }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getImage = () => {
    if (width <= 768) {
      return bannerSmallImage;
    } else {
      return bannerFrontHomePage;
    }
  };

  const { t } = useTranslation();

  const [blogData, setBlogData] = React.useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  // console.log(blogData);

  const getLatestBlogPosts = async () => {
    const { data } = await axios.get(
      `${apiUrl}/blogs?pagination[limit]=3&_start=0&sort=createdAt:DESC&populate=*`
    );
    setBlogData(data.data);
  };

  useEffect(() => {
    getLatestBlogPosts();
  }, []);

  const tabs = [
    {
      id: 1,
      name: "Meeting Demand",
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            We connect buyers and manufacturers online to meet the growing
            demand overseas for the efficient and secure management of global
            shipments in different modes and sectors.
          </p>
          
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
          
        </div>
      ),
    },
    {
      id: 2,
      name: "Greater Innovation",
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            WNVOEC helps the container industry embrace new technologies for
            greater transparency in freight shipping services and more reliable
            supply chain management.
          </p>
         
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
         
        </div>
      ),
    },
    {
      id: 3,
      name: "Sustainable Practices",
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            Our role in the Global shipping industry includes assisting members
            of the value chain in establishing sustainable practices and
            reducing their carbon footprint in international freight logistics.
          </p>
          
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
         
        </div>
      ),
    },
    {
      id: 4,
      name: t("home.site_info.networking.networking_main_heading"),
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            {t("home.site_info.networking.networking_para_part_1")} 
            {t("home.site_info.networking.networking_para_part_2")} 
            {t("home.site_info.networking.networking_para_part_3")}
          </p>
          
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
          
        </div>
      ),
    },
    {
      id: 5,
      name: t("home.site_info.boosting.boosting_main_heading"),
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            {t("home.site_info.boosting.boosting_para")}
          </p>
          
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
          
        </div>
      ),
    },
    {
      id: 6,
      name: t("home.site_info.training.training_main_heading"),
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            {t("home.site_info.training.training_para")}
          </p>
          
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
          
        </div>
      ),
    },
    {
      id: 7,
      name: t("home.site_info.representation.representation_main_heading"),
      content: (
        <div className="Home-Tab-Para">
          <p className="whatWeDoPara">
            {t("home.site_info.representation.representation_para")}
          </p>
          
            <img
              className="img-fluid"
              src={asset4}
              alt="Networking Facilities"
            />
          
        </div>
      ),
    },
  ];

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "320px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          // centerPadding: "100px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 769,
        settings: {
          // centerPadding: "50px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // centerPadding: "20px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>WNVOEC: Global Alliance Uniting the Shipping Industry</title>
        <meta
          name="description"
          content="WNVOEC is a custom global logistics network for companies and a global shippers association. We support global freight forwarding stakeholders through networking, providing insurance, efficient supply chain management, business expansion, and partnerships."
        />
      </Helmet>

      {/* Slider Home Section */}
      <div className="Main-Home-Container">
        <div
          id="carouselExampleIndicators"
          className="carousel carousel1 slide"
          style={{ height: "100vh", overflow: "hidden", position: "relative" }}
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item carousel-item1 active">
              <img
                src={getImage()}
                className="d-block w-100 img-fluid "
                alt="Slider background"
                style={{
                  height: "85%",

                  objectFit: "cover",
                  marginTop: "2rem",
                }}
              />
            </div>
            <div className="carousel-item carousel-item1">
              <div className="slider-text overlay">
                <div>
                  <h2>{t("home.header_slid_heading_2")}</h2>
                  <p>
                    {t("home.header_slid_para_2_part_1")} <br />{" "}
                    {t("home.header_slid_para_2_part_2")}
                  </p>
                </div>
              </div>
              <img
                src={imageOne}
                className="d-block w-100  "
                alt="Slider background"
                style={{ height: "92%", objectFit: "cover" }}
              />
            </div>
            <div className="carousel-item carousel-item1">
              <div className="slider-text overlay">
                <div>
                  <h2>{t("home.header_slid_heading_3")}</h2>
                  <p>
                    {t("home.header_slid_para_3_part_1")} <br />{" "}
                    {t("home.header_slid_para_3_part_2")}
                  </p>
                </div>
              </div>
              <img
                src={imageTwo}
                className="d-block w-100"
                alt="Slider background"
                style={{ height: "92%" }}
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
          {/* Slider ko nichay lay jana wala Button*/}
          {/* <div className="slider-bottom-btn ">
          <Link to="bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          </Link>
        </div> */}
        </div>

        {/* what we do section in home page after slider */}
        <div
          className="container home-do-section"
          style={{ marginBottom: "0.5rem", marginTop: "3rem" }}
        >
          <div className="row">
            <div className=" col-lg-1 col-md-1 col-sm-1"> </div>
            <div className=" col-lg-5 col-md-7 col-sm-10">
              <h2 className="Home-heading-1">{t("home.whatWeDoHeading")}</h2>
              <p className="Home-para-1">
                At WNVOEC, we bring together the shipping community to promote
                sustainable practices, drive efficiency, and share industry
                expertise.
              </p>
              <Link to="/products">
                <img
                  src={HomeImage}
                  alt="More Services"
                  className="img-fluid More-services-Img"
                />
              </Link>
            </div>
          </div>
          <div className=" col-lg-6 col-md-4 col-sm-1"> </div>
        </div>

        {/*Products & Services Section */}
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mt-5">
              <p className="products-heading-3">
                {t("productsPage.products-7")}
              </p>
              <p className="products-heading-4">
                {t("productsPage.products-8")}
              </p>
              <p className="products-para-3">
                {t("productsPage.products-9")}
                <br />
                {t("productsPage.products-10")}
              </p>
            </div>
          </div>
        </div>

        {/* Cards Section */}
        <div className="container mt-5 mb-4">
          <div className="row RowCard">
             <div className="">
                <a href="https://admin.rsrcontainers.com/login" target="_blank" rel="noopener noreferrer">
              <div class="flip-card mx-auto">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img
                      src={WeContainers}
                      alt="Avatar"
                      style={{
                        marginTop: "55px",
                      }}
                    />
                  </div>
                  <div
                    class="flip-card-back"
                    style={{  border: '3px solid #c7c7c7'}}
                  >
                     <img
                      src={WeContainers}
                      alt="Avatar"
                      style={{
                        marginTop: "55px",
                      }}
                    />
                  </div>
                </div>
              </div>
              </a>
            </div>
            <div className="">
              <div className="flip-card mx-auto">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={ProductsImage7}    alt="Avatar"
                      style={{
                        marginTop: "55px",
                      }}
                    />
                  </div>
                  <div
                    className="flip-card-back py-auto"
                    style={{ backgroundColor: "#77ad18" }}
                  >
                    <p>{t("productsPage.products-11")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <Link to="/wnTracker">
                <div className="flip-card mx-auto">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={ProductsImage5}
                        alt="Avatar"
                        style={{
                          marginTop: "55px",
                        }}
                      />
                    </div>
                    <div
                      className="flip-card-back"
                      style={{ backgroundColor: "#005B3A" }}
                    >
                      <p>{t("productsPage.products-12")}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="">
              <Link to="/wnPay">
                <div className="flip-card mx-auto">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={WnPay}
                        alt="Avatar"
                        style={{
                          marginTop: "55px",
                        }}
                      />
                    </div>
                    <div
                      className="flip-card-back"
                      style={{ backgroundColor: "rgba(0, 94, 94, 1)" }}
                    >
                      <p>{t("productsPage.products-13")}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="">
              <div className="flip-card mx-auto">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={ProductImage8}
                      alt="Avatar"
                      style={{
                        marginTop: "55px",
                      }}
                    />
                  </div>
                  <div
                    className="flip-card-back"
                    style={{ backgroundColor: "#27004C" }}
                  >
                    <p>{t("productsPage.products-14")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <Link to="/wndepot">
                <div class="flip-card mx-auto">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <img
                        src={ProductImage9}
                        alt="Avatar"
                        style={{
                          marginTop: "55px",
                        }}
                      />
                    </div>
                    <div
                      class="flip-card-back"
                      style={{ backgroundColor: "#560056" }}
                    >
                      <p>{t("productsPage.products-15")}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="">
              <div class="flip-card mx-auto">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img
                      src={WnSoc}
                      alt="Avatar"
                      style={{
                        marginTop: "55px",
                      }}
                    />
                  </div>
                  <div
                    class="flip-card-back"
                    style={{ backgroundColor: "#1D1D59" }}
                  >
                    <p>{t("productsPage.products-16")}</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="">
              <Link to="/wnhome">
                <div class="flip-card mx-auto">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <img
                        src={WnvoecProduct}
                        alt="Avatar"
                        style={{
                          marginTop: "55px",
                        }}
                      />
                    </div>
                    <div
                      class="flip-card-back"
                      style={{ backgroundColor: "rgba(221,30,37,255)" }}
                    >
                      <p>{t("productsPage.products-18")}</p>
                    </div>
               </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/*Global Freight Network Section */}
        <div className="container-fluid Map-Upper">
          <div className="row">
            <div className="col-12">
              <img src={HomeImage1} alt=".." className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="Map-Container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="Map-slider">
                  <button
                    onClick={previous}
                    className="border-0 bg-transparent"
                  >
                    <img src={wnHomeimage5} alt=".." className="img-fluid" />
                  </button>
                  <button onClick={next} className="border-0 bg-transparent">
                    <img src={wnHomeimage6} alt=".." className="img-fluid" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-5">
                <Slider
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  {...settings}
                >
                  <div>
                    <img
                      src={HomeImage3}
                      alt="Eco Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage4}
                      alt="Value Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage5}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage6}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage7}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage8}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage9}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage10}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage11}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={HomeImage12}
                      alt="Luxe Home"
                      className="img-fluid"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs Section */}
        <div className="container-fluid Home-Tab">
          <div className="row">
            <div className="col-lg-12">
              <p className="Home-Tab-Heading">What we Offer</p>
            </div>
          </div>
          <TabsComponent tabs={tabs} />
        </div>

        {/* Home Contact Us Section */}
        <div className="products-section-5">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12"></div>
              <div className="col-md-8 col-sm-12">
                <div className="products-content-5">
                  <div className="productConatctBtn">
                    <p className="products-heading-5">
                      We strive to create awareness and offer global business
                      solutions. Let’s work together to establish greener and
                      cleaner best industry practices!
                    </p>
                  </div>
                  <div>
                    <Link className="text-decoration-none" to="/joinus">
                      <button className="product-Contact-Btn">Join Us</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-12"></div>
            </div>
          </div>
        </div>

        {/* // membership benefits */}
        {/* <div className="container mt-5 mb-5" >
        <div className="row justify-content-center">
          <div className=" how-it-works-5">
            <div class="text-center mt-3 mb-5 section3">
              {t("home.membership_benefits.main_heading")}
            </div>
            <div className="row justify-content-between membership-benefits-section">
              <div className="col-md-3 mb-4 text-center">
                <div className="number">
                  <div className="group">
                    <img className="mask-icon" alt="" src={svg1} />
                  </div>
                </div>
                <b className="title3 section-4-heading1 text-center text-md-start ms-1">
                  {t(
                    "home.membership_benefits.partner_program.partner_program_main_heading"
                  )}
                </b>
                <div
                  className="whatWeDoPara mt-2"
                >
                  {t(
                    "home.membership_benefits.partner_program.partner_program_para"
                  )}
                </div>
                <img className="oval-icon" alt="" src="/oval.svg" />
                <img className="icon" alt="" src="/icon.svg" />
              </div>
              <div className="col-md-3 mb-4 text-center">
                <div className="number">
                  <div className="group">
                    <img className="mask-icon" alt="" src={svg2} />
                  </div>
                </div>
                <b className="title3 section-4-heading1 ms-1">
                  {t(
                    "home.membership_benefits.insurance_coverage.insurance_coverage_main_heading"
                  )}
                </b>
                <div
                  className="whatWeDoPara mt-2"
                >
                  {t(
                    "home.membership_benefits.insurance_coverage.insurance_coverage_para"
                  )}
                </div>
                <img className="oval-icon" alt="" src="/oval.svg" />
                <img className="icon" alt="" src="/icon.svg" />
              </div>
              <div className="col-md-3 mb-4 text-center">
                <div className="number">
                  <div className="group">
                    <img className="mask-icon" alt="" src={svg3} />
                  </div>
                </div>
                <b className="title3 section-4-heading1 ms-1">
                  {t(
                    "home.membership_benefits.repositioning.repositioning_main_heading"
                  )}
                </b>
                <div
                  className="whatWeDoPara mt-2"
                >
                  {t(
                    "home.membership_benefits.repositioning.repositioning_para"
                  )}
                </div>
                <img className="oval-icon" alt="" src="/oval.svg" />
                <img className="icon" alt="" src="/icon.svg" />
              </div>
              <div className="col-md-3 mb-4 text-center">
                <div className="number">
                  <div className="group">
                    <img className="mask-icon" alt="" src={svg4} />
                  </div>
                </div>
                <b className="title3 section-4-heading1">
                  {t(
                    "home.membership_benefits.tracking.tracking_main_heading"
                  )}
                </b>
                <div
                  className="whatWeDoPara mt-2"
                >
                  {t("home.membership_benefits.tracking.tracking_para")}
                </div>
              </div>
            </div>

            <button className="btn btn-purple btn-purple1 mx-auto d-block mt-5">
              {t("btns.viewMore")}
            </button>
          </div>
        </div>
      </div> */}

        {/* card with background image */}
        {/* <div className="content-9 mt-4 mb-4" >
        <div className="background-image-icon1">
          <div className="">
            <div className=" ">
              <div
                className="card p-4 card-background"
                style={{
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  width: "30rem",
                }}
              >
                <b className="get-touch-1 text-center">
                  {t("home.get_in_touch.main_heading")}
                </b>
                <div className="goal-of-1 text-center">
                  {t("home.get_in_touch.get_in_touch_para")}
                </div>
                <div className="let-us-1 text-center mb-4 mt-4">
                  {t("home.get_in_touch.letus_help")}
                </div>

                <RouterLink to="/contact" className=" mx-auto">
                  <button className="btn btn-sm btn-purple small-btn mx-auto">
                    {t("btns.contactNow")}
                  </button>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* Blogs Section */}
        <div className="container">
          <h1 className="mb-5 section3 mt-5">{t("home.blogs.main_heading")}</h1>
          <div className="blog_section row">
            {blogData?.map((post) => {
              return (
                <div className="col-lg-4 col-md-12 mb-4" key={post?.id}>
                  <div className="card blogs_card" style={{ height: "100%" }}>
                    <div
                      className="bg-image hover-overlay ripple"
                      data-mdb-ripple-color="light"
                    >
                      <img
                        src={`${imgUrl}${post?.attributes?.coverImage?.data?.attributes?.url}`}
                        alt=""
                        className="img-fluid"
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <a href="#!">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "rgba(251, 251, 251, 0.15)",
                          }}
                        ></div>
                      </a>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        {(eng && post?.attributes?.title) ||
                          (chinese && post?.attributes?.titleChinese) ||
                          (spanish && post?.attributes?.titleSpanish) ||
                          post?.attributes?.title}
                      </h5>
                      <p className="card-text">
                        {(eng &&
                          post?.attributes?.description?.slice(0, 190)) ||
                          (chinese &&
                            post?.attributes?.descriptionChinese?.slice(
                              0,
                              190
                            )) ||
                          (spanish &&
                            post?.attributes?.descriptionSpanish?.slice(
                              0,
                              190
                            )) ||
                          post?.attributes?.description?.slice(0, 190)}
                        ...
                      </p>
                      <Link
                        to={`/blog/${post?.attributes.slug}`}
                        className="btn btn-primary"
                      >
                        {t("btns.read")}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Link
            to="/blogs"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button className="btn btn-primary px-5 py-2">
              {t("btns.readAll")}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
