import React, { useEffect, useState } from "react";

import "../Assets/Contact.css";
import asset18 from "../Assets/SVG/Asset18.svg";
import asset36 from "../Assets/SVG/Asset36.svg";
import asset37 from "../Assets/SVG/Asset37.svg";
import asset21 from "../Assets/SVG/Asset21.svg";
import asset22 from "../Assets/SVG/Asset22.svg";
import asset23 from "../Assets/SVG/Asset23.svg";
import linkedin from "../Assets/SVG/linkedin.svg";
import MapChart from "../Components/MapChart";

import phone from "../Assets/SVG/bxs_phone-call.svg";
import mail from "../Assets/SVG/ic_sharp-email.svg";
import location from "../Assets/SVG/carbon_location-filled.svg";
import twitter from "../Assets/SVG/Group 1000001749.svg";
import insta from "../Assets/SVG/Group 1000001750.svg";
import discord from "../Assets/SVG/Group 1000001751.svg";
import ellipse1 from "../Assets/Png/Ellipse 793.png";
import ellipse2 from "../Assets/Png/Ellipse 794.png";
import down_arrow from "../Assets/SVG/down-filled-triangular-arrow 1.svg";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { apiCaller } from "../utils/api";
import { get } from "react-scroll/modules/mixins/scroller";
import { useTranslation } from "react-i18next";

function Contact({ user }) {
  const { t } = useTranslation();
  const [listOfCountries, setListOfCountries] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const methods = useForm({
    defaultValues: {
      prefix: "Mr.",
      firstName:"",
      lastName:"",
      email: "",
      phone: "",
      occupation: "",
      nationality: "",
      enquiry: "",
    },
  });

  useEffect(() => {
    const headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "aTRzTW5VekFyaUs0em5pd0FPRlNnYm5wY3lHcnNpU3l1RnFBdVhwUg=="
    );

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((data) =>
        setListOfCountries(
          data?.map((item) => ({
            value: item.name,
            iso: item.iso2,
          }))
        )
      )
      .catch((error) => {
        // console.log("error", error)
      });
  }, []);

  const onSubmit = async (data) => {
    // console.log("data",data)
    try {
      const response = await axios.post(
        `${apiUrl}/contacts`,
        {
          data: data,
        },
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${user.jwt}`,
        //   },
        // }
      );
      if (response.status === 200) {
        toast.success("Message Sent Successfully");
        methods.reset();
      }
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    // <>
    //   <div className="">
    //     {/* <iframe
    //       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7389213001848!2d103.96101225089758!3d1.3327904619951287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3cd6547ca93b%3A0xa11c6adcb93e65c9!2s5%20Changi%20South%20Ave%202%2C%20%2301%2001%2C%20Singapore!5e0!3m2!1sen!2s!4v1681108417463!5m2!1sen!2s"
    //       width="100%"
    //       height="800"
    //       style={{ border: "0", marginTop: "5rem" }}
    //       allowfullscreen=""
    //       loading="lazy"
    //       referrerpolicy="no-referrer-when-downgrade"
    //       title="."
    //     ></iframe> */}
    //   </div>
    //   {/* <div className="container py-5 mt-5">
    //     <div className="row ">
    //       <div className="col-md-8">
    //         <h1
    //           className="mb-4"
    //           style={{ color: "rgb(12, 52, 89)", fontWeight: "bold" }}
    //         >
    //           Unbeatable growth
    //         </h1>
    //         <p
    //           className="mb-4"
    //           style={{
    //             color: "rgb(161, 174, 183)",
    //             fontWeight: "500",
    //             fontSize: "1.1rem",
    //           }}
    //         >
    //           The container industry has experienced significant growth over the
    //           past few decades, driven by globalization and the increasing
    //           demand for international trade. WNVOEC aims to further boost this
    //           growth through greater awareness, collaboration, and efficient use
    //           of technologies.
    //         </p>
    //       </div>
    //     </div>
    //   </div> */}

    //   {/* <MapChart /> */}

    //   <div class="contact-form-container container">
    //     <form onSubmit={methods.handleSubmit(onSubmit)}>
    //       <div class="contact-form-form">
    //         <div class="contact-form-form-txt mt-5">
    //           <h1 style={{ color: "rgb(12, 52, 89)" }}>
    //             {t("contact.heading1")}
    //           </h1>
    //           <span style={{ color: "rgb(80, 93, 104)" }}>
    //             {t("contact.heading2")}
    //           </span>
    //           <p
    //             style={{
    //               fontSize: "16px",
    //               color: "rgb(161, 175, 193)",
    //               marginBottom: "30px",
    //               marginTop: "30px",
    //               fontWeight: "600",
    //             }}
    //           >
    //             {" "}
    //             {t("contact.para1")} <br />
    //             {t("contact.para2")} <br /> {t("contact.para3")}
    //           </p>

    //           <div class="contact-form-info mb-3">
    //             <img
    //               src={asset36}
    //               width="20px"
    //               height="20px"
    //               alt="."
    //               style={{ marginRight: "1rem" }}
    //             />
    //             <p
    //               style={{
    //                 fontSize: "16px",
    //                 color: "rgb(161, 175, 193)",
    //                 fontWeight: "600",
    //               }}
    //             >
    //               45 Changi South Ave 2, #01-01, Singapore 486133
    //             </p>
    //           </div>
    //           <div class="contact-form-info  mb-3">
    //             <img
    //               src={asset37}
    //               width="20px"
    //               height="20px"
    //               alt=""
    //               style={{ marginRight: "1rem" }}
    //             />
    //             <p
    //               style={{
    //                 fontSize: "16px",
    //                 color: "rgb(161, 175, 193)",
    //                 fontWeight: "600",
    //               }}
    //             >
    //               +65 62423656 /+65 96333990
    //             </p>
    //           </div>
    //           <div class="contact-form-info">
    //             <img
    //               src={asset18}
    //               width="20px"
    //               height="20px"
    //               alt=""
    //               style={{
    //                 marginRight: "1rem",
    //                 filter: "brightness(50%) contrast(80%) hue-rotate(0deg)",
    //               }}
    //             />
    //             <p
    //               style={{
    //                 fontSize: "16px",
    //                 color: "rgb(161, 175, 193)",
    //                 fontWeight: "600",
    //               }}
    //             >
    //               info@wnvoec.org
    //             </p>
    //           </div>
    //         </div>
    //         <div className="contact-form-form-details">
    //           <select
    //             {...methods.register("prefix", { required: true })}
    //             className="form-select"
    //             // aria-label="Default select example"
    //           >
    //             <option value="Mr.">Mr.</option>
    //             <option value="Mrs.">Mrs.</option>
    //             <option value="Ms.">Ms.</option>
    //             <option value="Miss.">Miss.</option>
    //             <option value="Mdm.">Mdm.</option>
    //             <option value="Dr.">Dr.</option>
    //             <option value="Prof.">Prof.</option>
    //           </select>
    //           <input
    //             type="text"
    //             name="name"
    //             id="name"
    //             placeholder=" Your Name"
    //             className="mb-3"
    //             {...methods.register("name", { required: true })}
    //           />
    //           <input
    //             type="email"
    //             name="email"
    //             id="email"
    //             placeholder="Email"
    //             {...methods.register("email", { required: true })}
    //           />
    //           <input
    //             type="text"
    //             name="phone"
    //             id="phone"
    //             placeholder="Phone"
    //             {...methods.register("phone", { required: true })}
    //           />
    //           <input
    //             type="text"
    //             name="occupation"
    //             id="occupation"
    //             placeholder="Occupation"
    //             {...methods.register("occupation", { required: true })}
    //           />

    //           <input
    //             defaultValue={" "}
    //             className="form-control custom-select"
    //             list={"nationality"}
    //             htmlFor={"nationality"}
    //             autocomplete="off"
    //             placeholder={"Nationality"}
    //             {...methods.register("nationality")}
    //           />
    //           <datalist id={"nationality"}>
    //             {listOfCountries?.map((country) => {
    //               return <option value={country?.value} />;
    //             })}
    //           </datalist>
    //           <textarea
    //             name="enquiry"
    //             id="enquiry"
    //             cols="52"
    //             className="mt-3"
    //             rows="7"
    //             placeholder="Enquiry..."
    //             {...methods.register("enquiry", { required: true })}
    //           ></textarea>
    //           <button className="btn btn-purple small-btn btn-sm">
    //             {t("btns.sendMessage")}
    //           </button>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </>

    <>
    <Helmet>
      <title>Join Freight Logistics and Global Shipping Network</title>
      <meta name="description" content="Freight logistics and global shipping network, WNVOEC, offers membership to industry stakeholders to promote international trade and cooperation within the shipping community worldwide."/>
    </Helmet>
      <div className="contactUs-main-container">
        <div className="contactUs-headingBox">
          <h2 className="contactUs-mainHeading">{t('contact.heading1')}</h2>
          <p className="contactUs-para">{t('contact.heading2')}</p>
        </div>
        <div className="contactUs-container">
          <div className="contactUs-info-container">
            <p className="contactUs-info-para">{t('contact.para1')}</p>
            <div className="contactUs-contactLinks">
              <div className="contactUs-linkBox contactUs-phone">
                <img src={phone} alt="Phone Img" style={{ color: "black", fontSize: "20px" }}
                />
                <a className="contactLink" href="" style={{ fontWeight:"500" }}>+65 96333990</a>
              </div>

              <div className="contactUs-linkBox contactUs-mail">
                <img src={mail} alt="Mail Img" style={{ color: "black", fontSize: "20px" }}
                />
                <a className="contactLink" href="" style={{ fontWeight:"500" }}>info@wnvoec.org</a>
              </div>

              <div className="contactUs-linkBox contactUs-location">
                <img src={location} alt="Location Img" style={{ color: "black", fontSize: "20px" }}
                />
                <a className="contactLink" style={{ width: "288px", fontWeight:"500" }} href="">51 Changi Business Park Center 2, The Singapore #06-04, Singapore 486066  </a>
              </div>
            </div>
            <div className="contactUs-socialLinks">
              <a
                href={"https://twitter.com/wnvoec"}
                target="_blank"
                rel="noreferrer" > <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 512 512" ><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
              </a>

              <a
                href="https://www.facebook.com/wnvoec"
                target="_blank"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg>
              </a>

              <a
                href="https://www.instagram.com/wnvoec/"
                target="_blank"
                rel="noreferrer"
              >
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
              </a>
              <a
                href="https://www.linkedin.com/company/wnvoec/"
                target="_blank"
                rel="noreferrer"
              >
               <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20"  viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
              </a>
           
            </div>
            <img className="ellipse-larg" src={ellipse1} />
            <img className="ellipse-small" src={ellipse2} />
          </div>
          <div className="contactUs-form-container">
            <form className="contactUs-form" onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="contactUs-form-gridbox">
                <div className="contactUs-form-fieldBox">
                  <p className="input-name-text">{t('contact.para2')}</p>
                  <div style={{ display: "flex" }}>
                    <select className="form-select-field" id="select"
                      {...methods.register("prefix", { required: true })} >
                      <option>Mr.</option>
                      <option>Mrs.</option>
                      <option>Ms.</option>
                      <option>Miss.</option>
                      <option>Mdm.</option>
                      <option>Dr.</option>
                      <option>Prof.</option>
                    </select>
                    <label for="select">
                      <img src={down_arrow} alt="Phone Img" style={{ marginRight: "10px" }} />
                    </label>
                    <input type="text" className="input-field"
                     {...methods.register("firstName", { required: true })} />
                  </div>
                </div>
                <div className="contactUs-form-fieldBox">
                  <p className="input-name-text">{t('contact.para3')}</p>
                  <input type="text" className="input-field"
                   {...methods.register("lastName", { required: true })} />
                </div>
                <div className="contactUs-form-fieldBox">
                  <p className="input-name-text">{t('contact.para4')}</p>
                  <input type="text" className="input-field"
                     {...methods.register("email", { required: true })} />
                </div>
                <div className="contactUs-form-fieldBox">
                  <p className="input-name-text">{t('contact.para5')}</p>
                  <input type="text" className="input-field" 
                     {...methods.register("phone", { required: true })}/>
                </div>
                <div className="contactUs-form-fieldBox">
                  <p className="input-name-text">{t('contact.para6')}</p>
                  <input type="text" className="input-field"
                   {...methods.register("occupation", { required: true })} />
                </div>
                <div className="contactUs-form-fieldBox">
                  <p className="input-name-text">{t('contact.para7')}</p>
                  <input type="text" className="input-field" 
                     {...methods.register("nationality", { required: true })}/>
                </div>
                <div className="contactUs-form-fieldBox contactUs-form-fieldBox-message">
                  <p className="input-name-text">{t('contact.para8')}</p>
                  <input type="text" className="input-field" placeholder="Write your message.."
                    {...methods.register("enquiry", { required: true })} />
                </div>
              </div>
              <button className="form-btn">{t('contact.para8')}</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
